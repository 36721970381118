import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link, navigate, graphql, useStaticQuery } from 'gatsby'
import styled, { css } from 'styled-components'
import GatsbyImage from 'gatsby-image'

import Carousel from '../Carousel'
import ArrowRightSvg from '../../images/arrow-right.svg'

import { device } from '../../styles/config'

const HomepageImageCarousel = ({
  slideshow,
  currentSlide,
  onProgrammeClick
}) => {
  const {
    allDatoCmsEvent: { edges },
    datoCmsCulture: {
      winterMode,
      winterCafeText,
      tischReservieren,
      tischReservierenText,
      winterCafeHeader,
      tischReservierenHeader
    }
  } = useStaticQuery(homepageHeaderQuery)

  const [currentColors, setCurrentColors] = useState({
    primary: slideshow[0].primaryColor.hex,
    secondary: slideshow[0].secondaryColor.hex
  })

  useEffect(() => {
    setTimeout(() => {
      setCurrentColors({
        primary: slideshow[currentSlide].primaryColor.hex,
        secondary: slideshow[currentSlide].secondaryColor.hex
      })
    }, 900)
  }, [currentSlide])

  const events = edges
    .filter(({ node }) => {
    // filter for events happening today or in the future
      const eventEndOfDay = new Date(node.time)
      eventEndOfDay.setHours(23, 59, 59, 999)
      const currentTime = new Date()

      return currentTime <= eventEndOfDay
    })
    .filter((event, index) => index < 4)
    .map(({ node }) => {
      return {
        type: node.eventType.eventTypeShort,
        time: new Date(node.time).toLocaleDateString('de-DE', {
          month: '2-digit',
          day: '2-digit'
        }),
      }
    })

  return (
    <Container>
      <Carousel currentSlide={currentSlide} autosize>
        {slideshow.map((slide, index) => (
          <GatsbyImage
            key={index}
            fluid={slide.image.fluid}
          />
        ))}
      </Carousel>
      <InfoBox>
        <Program color={currentColors.primary} onClick={onProgrammeClick}>
          {!winterMode &&
            <ProgramItemList>
              <ProgramHeader>Kulturprogramm</ProgramHeader>
              {events.map((event, index) => (
                <ProgramItem key={index}>
                  <div>{event.type}</div>
                  <div>{event.time}</div>
                </ProgramItem>
              ))}
              <ProgramLink>
                <ArrowContainer>
                  <AnimationContainer/>
                  <Arrow/>
                </ArrowContainer>
              </ProgramLink>
            </ProgramItemList>
          }
          {winterMode &&
            <div>
              <ProgramHeader alignLeft={winterMode}>{winterCafeHeader}</ProgramHeader>
              <div>{winterCafeText}</div>
            </div>
          }
        </Program>
        <Rent
          color={currentColors.secondary}
          onClick={() => (!winterMode || !tischReservieren) && navigate('/vermietung')}
          winterMode={winterMode && tischReservieren}
        >
          {(!winterMode || !tischReservieren) &&
            <>
              <h4>Insel Mieten</h4>
              <Arrow/>
            </>
          }
          {winterMode && tischReservieren &&
            <>
              <ProgramHeader>{tischReservierenHeader}</ProgramHeader>
              <div dangerouslySetInnerHTML={{ __html: tischReservierenText }}/>
            </>
          }
        </Rent>
      </InfoBox>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
`

const InfoBox = styled.div`
  position: absolute;
  bottom: 0;
  right: 8%;
  left: 8%;
  transform: translateY(28%);
  display: flex;
  justify-content: center;
  align-items: flex-end;

  @media ${ device.md } {
    display: none;
  }
`

const ArrowContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`

const AnimationContainer = styled.div`
  position: relative;
  height: 1.75rem;
  overflow: hidden;
  flex: 1;

   &:after {
    content: 'Details';
    display: block;
    position: absolute;
    font-size: 0.875rem;
    letter-spacing: 3.27px;
    font-weight: bold;
    line-height: 1.75rem;
    text-transform: uppercase;
    right: 0;
    top: 0;
    margin-right: 1.25rem;
    transform: translate3d(140%,0,0);
    transition: transform 0.5s 0.1s;
  }
`

const Arrow = styled(ArrowRightSvg)`
  transition: transform 0.5s;
  transform: translate3d(0,0,0);

  path {
    fill: white;
  }
`

const Program = styled.div`
  width: 56%;
  height: 0px;
  padding-bottom: 56%;
  background-color: ${ props => `${ props.color }FF` };
  transition: background-color 0.3s ease-in-out;
  position: relative;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    z-index: -1;
  }

  > div {
    width: 100%;
    height: 100%;
    padding: 17%;
    position: absolute;
    color: white;
    white-space: pre-wrap;
    font-size: 0.875rem;
    line-height: 2;
    letter-spacing: 0.42px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  @media ${ device.hover } {
    &:hover {
      background-color: ${ props => `${ props.color }D9` };
      
      ${ Arrow } {
        transform: translate3d(10px,0,0);
      }

      ${ AnimationContainer } {
        &:after {
          transform: translate3d(0,0,0);
        }
      }
    }
  }

`

const ProgramHeader = styled.h4`
  color: white;
  margin-bottom: 0.75rem;
  width: 100%;
  text-align: ${ props => props.alignLeft ? 'left' : 'center' };
`

const ProgramItemList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ProgramItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-size: 0.875rem;
  line-height: 2;
  letter-spacing: 0.42px;
`

const Rent = styled.div`
  width: 46%;
  padding: 8% 6%;
  background-color: ${ props => `${ props.color }FF` };
  transition: background-color 0.3s ease-in-out;
  margin-bottom: 4rem;
  cursor: pointer;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    z-index: -1;
  }

  ${ props => !props.winterMode && css`
    color: white;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items:  center;
  ` }

  ${ props => props.winterMode && css`
    padding: 8% 5%;
    
    ${ ProgramHeader } {
      text-align: initial;
      width: initial;
    }
  ` }

  p, a {
    color: white;
    white-space: pre-wrap;
    font-size: 0.875rem;
    line-height: 2;
    letter-spacing: 0.42px;
  }

  @media ${ device.hover } {
    &:hover {
      background-color: ${ props => `${ props.color }D9` };
      
      ${ Arrow } {
        transform: translate3d(10px,0,0);
      }
    }
  }
`

const ProgramLink = styled.div`
  display: flex;
  width: 100%;
  justify-content: ${ props => props.winterMode ? 'flex-start' : 'flex-end' };
  align-items: center;
  margin-top: 2rem;

  svg {
    margin-left: ${ props => props.winterMode && '10px' };

    path {
      fill: white;
    }
  }
`

const homepageHeaderQuery = graphql`
  query eventQuery {
    allDatoCmsEvent(sort: {fields: time, order: ASC}) {
      edges {
        node {
          time
          eventType {
            eventTypeShort
          }
        }
      }
    }
    datoCmsCulture {
      winterMode
      winterCafeText
      winterCafeHeader
      tischReservieren
      tischReservierenHeader
      tischReservierenText
    }
  }
`

export default HomepageImageCarousel
