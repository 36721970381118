import React, { useState, useMemo, useCallback } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled, { css } from 'styled-components'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { Col, Row } from 'react-styled-flexboxgrid'

import EventCard from './EventCard'
import { colors } from '../../styles/config'
import { getMonthName } from '../../utils/time'

const EventCalendar = () => {
  const [archiveVisible, setArchiveVisible] = useState(null)
  const { allDatoCmsEvent: { edges } } = useStaticQuery(EventsQuery)

  const groupedByMonth = useMemo(() => {
    return groupEventsByMonth(edges)
  }, [])

  const pastEvents = useMemo(() => {
    return getPastEvents(edges)
  }, [])

  const lastYear = useMemo(() => {
    return pastEvents && Object.keys(pastEvents) && Object.keys(pastEvents).reverse()[0]
  }, [])

  const [selectedYear, setSelectedYear] = useState(() => {
    return lastYear
  })

  const showArchive = useCallback(() => {
    setSelectedYear(lastYear)
    setArchiveVisible(true)
  }, [])

  const hideArchive = useCallback(() => {
    setArchiveVisible(false)
  }, [])

  return (
    <Container>
      <Tabs forceRenderTabPanel={true}>
        <Row>
          <Col xs={12} sm={5} smOffset={3}>
            <Months>
              {groupedByMonth.map((month, index) => (
                <Month onClick={hideArchive} key={index}>{month.monthName}</Month>
              ))}
              <Month onClick={showArchive}>Archiv</Month>
            </Months>
          </Col>
        </Row>
        {archiveVisible && (
          <Row>
            <Col xs={12} sm={5} smOffset={3}>
              <Years>
                {Object.keys(pastEvents).reverse().map((year, index) => (
                  <Year onClick={() => {
                    setSelectedYear(year)
                  }} selected={selectedYear === year} key={index}>{year}</Year>
                ))}
              </Years>
            </Col>
          </Row>
        )}

        <PanelContainer>
          {groupedByMonth.map((month, index) => (
            <MonthPanel key={index}>
              <Row>
                {month.events.map((event, index) => <EventCard key={index} {...event}/>)}
              </Row>
            </MonthPanel>
          ))}
          {archiveVisible && (
            <MonthPanel>
              <Row>
                {pastEvents[selectedYear].map((event, index) => <EventCard key={index} {...event}/>)}
              </Row>
            </MonthPanel>
          )}
        </PanelContainer>
      </Tabs>
    </Container>
  )
}

const Container = styled.div`
  background-color: ${ colors.neutralLight };
  ${ props => props.theme.wrapper }
  margin-top: 3.25rem;
  margin-bottom: 15.625rem;
`

const tabCss = css`
  list-style: none;
  font-size: 0.875rem;
  letter-spacing: 3.27px;
  line-height: 2.21;
  font-weight: bold;
  text-transform: uppercase;
  color: ${ ({ selected }) => selected ? colors.primary : colors.secondary };
  cursor: pointer;
  margin-right: auto;

  &:focus {
    outline: 0;
  }
`

const tabListCss = css`
  display: flex;
  justify-content: flex-start;
  margin: 0;
  margin-bottom: 1.5rem;
`

const Month = styled(Tab)`
  ${ tabCss };
`

const Months = styled(TabList)`
  ${ tabListCss };
`

const Year = styled.div`
  ${ tabCss };
`

const Years = styled.div`
  ${ tabListCss };
  margin-bottom: 2.85rem;
`

const PanelContainer = styled.div`
  position: relative;
`

const MonthPanel = styled(TabPanel)`
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s, visibility 0.5s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  &.react-tabs__tab-panel--selected {
    opacity: 1;
    visibility: visible;
    position: static;
  }
`

function groupEventsByMonth (edges) {
  return edges
    .filter(({ node }) => {
      // filter for events happening today or in the future
      const eventEndOfDay = new Date(node.time)
      eventEndOfDay.setHours(23, 59, 59, 999)
      const currentTime = new Date()

      return currentTime <= eventEndOfDay
    })
    .map(({ node }) => node)
    .reduce((months, current) => {
      // group by months
      months[new Date(current.time).getMonth()].push(current)
      return months
    }, new Array(12)
      .fill(null).map(() => []))
    .map((month, index) => {
      if (month.length > 0) {
        return {
          events: month,
          monthName: getMonthName(index),
          monthIndex: index,
          year: new Date(month[0].time).getFullYear()
        }
      }
    })
    .filter(month => month !== undefined)
    .sort((current, next) => {
      if (current.year < next.year) {
        return -1
      }

      if (current.year > next.year) {
        return 1
      }

      if (current.year === next.year) {
        return current.monthIndex < next.monthIndex ? -1 : 1
      }
    })
}

function getPastEvents (edges) {
  return edges
    .filter(({ node }) => {
      return new Date(node.time).getTime() < new Date().getTime()
    })
    .map(({ node }) => node)
    .reduce((yearGroups, event) => {
      const currentYear = new Date(event.time).getFullYear()

      if (!yearGroups[currentYear]) {
        yearGroups[currentYear] = []
      }

      yearGroups[currentYear].push(event)

      return yearGroups
    }, {})
}

EventCalendar.propTypes = {
  // name: PropTypes.string,
  // header: PropTypes.string,
  // children: PropTypes.node,
}

const EventsQuery = graphql`
  query eventsQuery {
    allDatoCmsEvent(sort: {fields: time}) {
      edges {
        node {
          name
          time
          wholeDay
          featured
          description
          details
          eventType {
            eventType
          }
          video {
            width
            height
            providerUid
          }
          image {
            fluid(maxWidth: 300, imgixParams: {
              fm: "jpg",
              auto: "compress,format"
            }) {
              ...GatsbyDatoCmsFluid_noBase64
            }
          }
        }
      }
    }
  }
`

export default EventCalendar
